import React, { useState } from 'react'
import { IoMdPlay } from 'react-icons/io'
import Img from 'gatsby-image'

/** @jsx jsx */
import { jsx, Styled, Text, Container } from 'theme-ui'

import BlockContent from './block-content'
import RoleList from './role-list'

import { TextBlockContainer } from './text-block-container'
import { mediaQueries } from '../gatsby-plugin-theme-ui/media-queries'
import { VideoModalPlayer } from './video-modal-player'

function Project(props) {
  const {
    _rawBody,
    title,
    categories,
    mainImage,
    members,
    endedAt,
    client,
    deliverables,
    videoUrl,
  } = props

  const tableDetailStyle = {
    borderBottom: '1px solid',
    borderColor: 'mute',
    py: 2,
    pr: 4,
    fontSize: 3
  }

  const [showModal, setShowModal] = useState(false)

  return (
    <article>
      {props.mainImage && mainImage.asset?.fluid && (
        <div
          sx={{
            position: 'relative',
          }}
        >
          <Img
            fluid={mainImage.asset.fluid}
            alt={mainImage.alt}
            sx={{
              width: '100%',
              height: '50vh',
              '::after': {
                content: `""`,
                position: `absolute`,
                top: 0,
                left: 0,
                width: `100%`,
                height: `100%`,
                backgroundColor: mainImage?.asset?.metadata?.palette?.darkMuted?.background,
                opacity: 0.5,
              },
            }}
          />
          {videoUrl && (
            <div
              sx={{
                position: `absolute`,
                top: 0,
                left: 0,
                width: `100%`,
                height: `100%`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`,
              }}
            >
              <div
                sx={{
                  display: `flex`,
                  flexDirection: `column`,
                }}
              >
                <button
                  aria-label="Watch video"
                  sx={{
                    bg: `unset`,
                    border: 0,
                    fontSize: 8,
                    color: `white`,
                    cursor: `pointer`,
                  }}
                  onClick={() => {
                    console.log('hello')
                    setShowModal(!showModal)
                  }}
                >
                  <IoMdPlay />
                </button>
                <span sx={{ color: `white` }}>Watch the video</span>
              </div>
            </div>
          )}
          {videoUrl && (
            <VideoModalPlayer
              url={videoUrl}
              isOpen={showModal}
              onDismiss={() => setShowModal(false)}
              overlayColor={mainImage?.asset?.metadata?.palette?.darkMuted?.background}
            />
          )}
        </div>
      )}
      <Container>
        <div>
          <div
            sx={{
              px: [4, 4, 4, 4, 4, 0],
              pt: [5, 5, 5, 5, 5, 6],
            }}
          >
            <TextBlockContainer>
              <Styled.h1 sx={{ fontWeight: 'bold', textAlign: 'center', mt: 0 }}>{title}</Styled.h1>
              <aside>
                <table
                  sx={{
                    borderSpacing: 0,
                    mb: 5,
                    [mediaQueries.lg]: {
                      float: 'right',
                      pb: 4,
                      pl: 4,
                      width: '45%',
                    },
                  }}
                >
                  <thead>
                    <tr>
                      <th sx={{ textAlign: 'left', pb: 4 }} colSpan="2">
                        <Text as="h2" variant="text.heading.small" sx={{ pt: 0 }}>
                          Project details
                        </Text>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td sx={tableDetailStyle}>
                        <Text as="h3" sx={{ fontSize: 3 }}>
                          Project:
                        </Text>
                      </td>
                      <td sx={tableDetailStyle}>
                        <Text as="span">{title}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td sx={tableDetailStyle}>
                        <Text as="h3" sx={{ fontSize: 3 }}>
                          Client:
                        </Text>
                      </td>
                      <td sx={tableDetailStyle}>
                        <Text as="span">{client && client.name}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td sx={tableDetailStyle}>
                        <Text as="h3" sx={{ fontSize: 3 }}>
                          Deliverable(s):
                        </Text>
                      </td>
                      <td sx={tableDetailStyle}>
                        <Text as="span">{deliverables?.length > 0 && deliverables.join(', ')}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td sx={tableDetailStyle}>
                        <Text as="h3" sx={{ fontSize: 3 }}>
                          Year:
                        </Text>
                      </td>
                      <td sx={tableDetailStyle}>
                        <Text as="span">{endedAt}</Text>
                      </td>
                    </tr>
                    <tr>
                      <td sx={tableDetailStyle}>
                        <Text as="h3" sx={{ fontSize: 3 }}>
                          Field:
                        </Text>
                      </td>
                      <td sx={tableDetailStyle}>
                        <Text as="span">Web Development</Text>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </aside>
              {_rawBody && (
                <BlockContent blocks={_rawBody || []} sx={{ '> p:last-child': { mb: 6 } }} />
              )}
            </TextBlockContainer>
          </div>
        </div>
      </Container>
    </article>
  )
}

export default Project
