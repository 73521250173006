import React from 'react'
import ReactPlayer from 'react-player/lazy'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import '@reach/dialog/styles.css'

/** @jsx jsx */
import { jsx } from 'theme-ui'

export const VideoModalPlayer = ({ url, isOpen, onDismiss, overlayColor }) => {
  return (
    <DialogOverlay
      isOpen={isOpen}
      onDismiss={onDismiss}
      sx={{
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        backgroundColor: `${overlayColor}a4`,
        zIndex: 1,
      }}
    >
      <DialogContent
        sx={{
          p: 0,
          width: `90vw`,
        }}
        aria-label="Video modal"
      >
        <div
          sx={{
            pt: `56.25%`,
            position: `relative`,
          }}
        >
          <ReactPlayer
            url={url}
            width="100%"
            height="100%"
            sx={{
              position: `absolute`,
              top: 0,
              left: 0,
            }}
          />
        </div>
      </DialogContent>
    </DialogOverlay>
  )
}
